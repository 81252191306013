<template>
  <!--引导页-->
  <div>
    <div class="personal-info1">
      <van-form @submit="onSubmit">
        <van-field
                v-model="name"
                name="姓名"
                label="姓名"
                placeholder="姓名"
                :rules="[{ required: true, message: '请填写姓名' }]"
        />
          <van-radio-group v-model="sex" direction="horizontal"style="background: #FFFFFF">
            <span style="margin-left: 15px;color: #646566;">
              性别:
            </span>
            <van-radio name="1" style="margin-left: 65px">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>

        <van-field
                v-model="phone"
                type="password"
                name="电话号码"
                label="电话号码"
                placeholder="电话号码"
                :rules="[{ required: true, message: '请填电话号码' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>

  </div>
</template>


<script>

export default {
  components: {
  },
  data() {
    return {
      index: 0,
      name:undefined,
      sex:undefined,
      phone:undefined,
    };
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
  body{
    background: #F6F6F6;
  }
  .personal-info1{
    border: 1px #1083FC solid;
    height: 120px;
    margin-top: 70px;
  }

</style>
